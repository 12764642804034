import React from 'react'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'
import serviceIcon4 from '../../assets/images/services/service-icon4.png'
import serviceIcon5 from '../../assets/images/services/service-icon5.png'
import serviceIcon6 from '../../assets/images/services/service-icon6.png'

const OurFeaturedServices = () => {
  return (
    <section className="services-area pt-50 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <h2>Our Featured Services</h2>
          <p>Looking to address Affordability, Security, Maximum Profitability and Seamless Integration? Here's how CodeJays can help</p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon1} alt="Web Development" title='Web Development' />
              </div>
              <h3>Web Development</h3>
              <p className='text-center'>
                Responsive design<br />
                eCommerce<br />
                Shopift/Wix/Wordpress<br />
                UX/UI design<br />
                Extension & Plugins<br />
                SEO Friendly<br />
              </p>
            </div>
          </div>



          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon3} alt="iOS/Android" title='iOS/Android' />
              </div>
              <h3>iOS/Android</h3>
              <p className='text-center'>Native platforms<br />
                Webview<br />
                Cross platforms<br />
                React Native<br />
                Flutter<br />
                Expo<br /></p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon4} alt="Backend" title='Backend' />
              </div>
              <h3>Backend</h3>
              <p className='text-center'>Node.js<br />
                Express.js<br />
                Google<br />
                AWS<br />
                Mongo<br />
                Azure<br /></p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon5} alt="Marketing" title='Marketing' />
              </div>
              <h3>Marketing</h3>
              <p className='text-center'>SEM/SEO for cost-effective acquisition<br />
                Whats App Marketing<br />
                Email Marketing<br />
                Automations<br />
                Graphic Designs<br />
                Social Media Marketing<br /></p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon6} alt="Crypto Development" title='Crypto Development' />
              </div>
              <h3>Crypto Development</h3>
              <p className='text-center'>Custom Token<br />
                Smart Contracts (Solana, Solidity)<br />
                HyperLedger Development<br />
                Decentralized Finance (DeFi)<br />
                Non-Fungible Tokens (NFTs)<br />
                POS/POW Consensus<br /></p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon2} alt="Webview" title='Webview' />
              </div>
              <h3>Project Handover</h3>
              <p className='text-center'>Boost the success of your ongoing projects<br /> with our result-oriented support.<br />
                <br /><br /><br /><br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurFeaturedServices