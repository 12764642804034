import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import bannerImg from '../../assets/images/programmer-amico.png'

const Banner = () => {
    return (
        <div className="it-services-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-content">
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1 style={{ fontSize: 30 }}> Leading End-to-End Custom Web Development Software Services in Toronto That 10x Your Business </h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p> Code Jays is a team of Toronto web development experts specializing in tailored, custom-built business solutions. We offer services in crypto, marketing, web, and iOS/Android. Our efficient services drive client results</p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <Link to="tel:14168386065" className="default-btn">
                                        <i className="flaticon-right"></i>
                                        Free Consultation
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <img src={bannerImg} alt="Custom Web Development" title='Custom Web Development' />
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Banner