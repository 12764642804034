import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import OurServices from "../components/Index/OurServices"
import OurFeatures from "../components/Index/OurFeatures"
import Testimonials from "../components/Index/Testimonials"
import Partner from "../components/Index/Partner"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import OurFeaturedServices from "../components/Index/OurFeaturedServices"
import AboutUs from "../components/DigitalMarketingAgency/AboutUs"
import Services from "../components/DigitalMarketingAgency/Services"
import FunFacts from "../components/DigitalMarketingAgency/FunFacts"
import Projects from "../components/DigitalMarketingAgency/Projects"
import Pricing from "../components/DigitalMarketingAgency/Pricing"
import StartProject from "../components/DigitalMarketingAgency/StartProject"
import OurBlog from "../components/DigitalMarketingAgency/BlogPost"
import OurProcess from "../components/Index/OurProcess"
import HowItWork from "../components/Index/HowItWork"


//import TeamMember from "../components/Index/TeamMember"
//import RecentProjects from "../components/Index/RecentProjects"
//import Pricing from "../components/Index/Pricing"
//import OurSolutions from "../components/Index/OurSolutions"
//import OurBlog from "../components/Index/OurBlog"

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <Banner />
      <OurFeaturedServices />
      <Partner />
      <AboutUs />

      {/* <Services />
      <OurFeatures /> */}
      <FunFacts />
      <HowItWork />
      <Projects />
      {/* <Pricing /> */}
      <StartProject />
      {/* <OurBlog /> */}
      <Footer />
    </Layout>
  )
}

export default Home